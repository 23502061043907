import React, { useEffect, useState } from 'react';
import { ButtonBase } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-i18next';
import ReactJoyride from 'react-joyride';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  AfterListingWidgets,
  ConditionalRender,
  FeaturedMarketingProps,
  Modal,
  withFeaturedMarketingWidget,
} from 'components/common';
import { WidgetsContainerExtra } from 'components/common/AfterListingWidgets/after-listing-widgets.tsyle';
import LoadingPage from 'components/pages/LoadingPage/component';
import * as colors from 'components/styles/colors';

import ShowIf from 'components/views/ShowIf';
import { useTours } from 'hooks';
import { ReactComponent as BubbleIcon } from 'images/ic_bubble.svg';
import { ReactComponent as PrekursorIcon } from 'images/ic_prekursor.svg';
import { ReactComponent as OrderDeliveredIcon } from 'images/order-delivered-square.svg';
import { ReactComponent as OrderReturnedIcon } from 'images/order-returned-square.svg';
import { Order, OrderPrecursorStatus, OrderStatus, swipeRxPt } from 'services';
import { MarketingFeaturesType } from 'services/swipe-rx-pt/resources/marketings/constants';

import { State as StoreState } from 'store';
import { getMarketingFeatures } from 'store/reducers/MarketingFeatures/actions';

import { resetPurchaseOrderDetails, fetchPurchaseOrderDetails } from 'store/reducers';
import { closeOrderStatus } from 'store/reducers/Orders/actions';
import { AnalyticsEvent } from 'utils/Analytics/interfaces';
import { trackContactCustomerCare } from 'utils/Analytics/Segment';
import { makeAnalyticsRequest } from 'utils/Analytics/Segment/make-analytics-request';
import { getCustomerSuccessSupport, getWhatsappSupportLink } from 'utils/MarketConfig';
import { toursTemplate } from 'utils/tours';
import { IInvoiceReturn } from 'store/reducers/Return';
import { PrecursorStatus, PrecursorStatusReason } from 'store/reducers/Orders';
import { fetchDeposits } from 'store/reducers/Payment/actions';
import { RegularSPDocumentDownload } from 'components/pages/OrderPage/components/PrekursorDocument/RegularSPDocumentDownload.component';
import { OtpFrame } from 'components/pages/DigitalSignaturePage/components/otp.component';
import { ORDER_DETAIL_STATUS_FOR_DS } from 'components/pages/DigitalSignaturePage/constants/digitalSignaturePage.constants';
import { PrekursorDocument } from '../../../OrderPage/components/PrekursorDocument';
import { ORDER_CONFIRMED_INDEX } from '../../constants';
import { useOrderItemReturn } from '../../hooks/use-order-return.hook';
import { OrdersRatingEntrypoint } from '../orders-rating';
import { OrderStatusModal } from '../orders-status-modal';
import { OrdersModalReturnPagination } from './order-modal-return-pagination';
import { Section } from './order-modal-section';
import { OrdersModalCreditMemo } from './orders-modal-credit-memo.component';
import { OrdersModalLoyaltyPoint } from './orders-modal-loyalty-point.component';
import { OrdersModalProductsPagination } from './orders-modal-products-pagination.component';
import { OrdersModalRefundInfo } from './orders-modal-refund-info.component';
import { OrdersModalSubTitle } from './orders-modal-subtitle.component';
import { OrdersModalSummaryOrders } from './orders-modal-summary-orders.component';
import { OrdersModalSummaryPayments } from './orders-modal-summary-payments.component';
import { S } from './orders-modal.styles';

interface Props {
  open: boolean;
  returned?: boolean;
  onClose: () => void;
  t: any;
}

const OrdersModalBase: React.FC<React.PropsWithChildren<Props & FeaturedMarketingProps>> = ({
  open,
  onClose,
  t,
  featureType,
  widgets = [],
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const mapPrecursorReasonToTranslated = (reasons: PrecursorStatusReason[]): string[] =>
    reasons.map((reason) => {
      const freeText = reason.text ? `- ${reason.text}` : '';
      const translate = t(`prekursor.reasons.${reason.name}`);
      return `${translate} ${freeText}`;
    });

  const [shouldShowRating, setShouldShowRating] = useState(false);
  const [shouldShowOtpFrame, setShouldShowOtpFrame] = useState(false);
  const [isOtpFrameDisabled, setIsOtpFrameDisabled] = useState(false);
  const [digitalSignatureVerified, setDigitalSignatureVerified] = useState(false);

  const customerSuccessSupport = getCustomerSuccessSupport();
  const { handleJoyride, tourState } = useTours({
    t,
    tourName: 'orders_modal',
    template: toursTemplate,
    initialTour: {
      title: t('tour.orderModal.title'),
      description: t('tour.orderModal.description'),
    },
  });

  const {
    purchaseOrder: { orderDetail, loading },
    orders: { orderStatus },
  } = useSelector((state: StoreState) => state, shallowEqual);
  const [orderedItems, deliveredItems, returnedItems] = useOrderItemReturn(orderDetail as Order);
  const refunds = (orderDetail.invoice?.return as IInvoiceReturn[]) ?? [];

  const precursorStatus = orderDetail.precursor_status as PrecursorStatus;
  const showPrekursor = precursorStatus && orderDetail.status !== OrderStatus.CANCELLED;
  const shouldSkipOtpFrame = digitalSignatureVerified || isOtpFrameDisabled || !shouldShowOtpFrame;
  const isPrecursorRejected = precursorStatus?.status === OrderPrecursorStatus.DOCUMENT_REJECTED;
  const precursorReasons = precursorStatus?.reason;
  const precursorReasonsTranslated: string[] =
    precursorReasons && precursorReasons.length > 0 ? mapPrecursorReasonToTranslated(precursorReasons) : [];

  const handleClose = (): void => {
    if (Object.keys(orderDetail).length > 0) {
      dispatch(resetPurchaseOrderDetails());
    }
    setIsOtpFrameDisabled(false);
    onClose();
  };

  const whatsappSupportLink = getWhatsappSupportLink(
    t(`needAssistanceOrderViaWhatsapp`, { poNumber: orderDetail.po_number }),
  );

  const openChat = (): void => {
    trackContactCustomerCare(orderDetail.pharmacy_id);

    if (whatsappSupportLink) {
      window.open(whatsappSupportLink);
    } else {
      window.open(customerSuccessSupport.link, customerSuccessSupport.target);
    }
  };

  const handleCloseStatus = (): void => {
    dispatch(closeOrderStatus());
  };

  const viewOrderEvent = (): void => {
    makeAnalyticsRequest('pt_view_order', AnalyticsEvent.Page, {
      referrer: location.pathname,
      pharmacyId: orderDetail.pharmacy_id,
    });
  };

  const handleVerified = (): void => {
    dispatch(fetchPurchaseOrderDetails(orderDetail?.id));
  };

  useEffect(() => {
    if (orderDetail.pharmacy_id) {
      viewOrderEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail.pharmacy_id]);

  useEffect(() => {
    setDigitalSignatureVerified(false);
    const checkDigitalSignature = async () => {
      const isDigitalSignatureEnabled = await swipeRxPt.digitalSignature.checkRestriction(orderDetail);
      const isStatusAvailableForDS = ORDER_DETAIL_STATUS_FOR_DS.includes(orderDetail.status);
      const shouldShowOtpFrame =
        isStatusAvailableForDS &&
        !isDigitalSignatureEnabled.data &&
        !(
          orderDetail?.precursor_status &&
          orderDetail?.precursor_status?.document_type === 'Manual' &&
          orderDetail?.precursor_status?.document_url
        );
      setShouldShowOtpFrame(shouldShowOtpFrame);
    };

    checkDigitalSignature();
  }, [orderDetail.id]);

  useEffect(() => {
    if (orderDetail.digital_signature && orderDetail.digital_signature.authenticated_at) {
      setDigitalSignatureVerified(true);
    } else {
      setDigitalSignatureVerified(false);
    }
  }, [orderDetail]);

  useEffect(() => {
    const isOrderConfirmed = location.pathname.split('/')[ORDER_CONFIRMED_INDEX] === 'order-confirmed';
    dispatch(getMarketingFeatures({ featureType }));
    if (!isOrderConfirmed) setShouldShowRating(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClosePrekursorDocument = (): void => {
    viewOrderEvent();
  };

  useEffect(() => {
    if (refunds.length > 0) {
      const depositIds = refunds.map((refund) => refund?.deposit_id);
      if (depositIds.length > 0) dispatch(fetchDeposits({ ids: depositIds }, orderDetail?.pharmacy_id));
    }
  }, [refunds]);

  return (
    <Modal open={open} onClose={handleClose} style={{ zIndex: 100 }}>
      <ReactJoyride
        {...tourState}
        callback={handleJoyride}
        styles={{ options: { primaryColor: colors.DARK_PRIMARY_COLOR } }}
      />
      <S.Wrapper>
        <S.Header>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center" justifyContent="flex-start">
              <S.ButtonBack onClick={handleClose} data-testid="back-btn">
                <S.BackIcon />
              </S.ButtonBack>
              <S.Title align="left">{t('details')}</S.Title>
            </Grid>
            <ButtonBase onClick={openChat} data-testid="order-activity-open-chat">
              <BubbleIcon />
            </ButtonBase>
          </Grid>
        </S.Header>
        <OrdersModalSummaryOrders />
        <ShowIf condition={shouldShowOtpFrame && !!orderDetail?.id}>
          <S.Header>
            <OtpFrame
              isCardView
              isOtpFrameDisabled={isOtpFrameDisabled}
              hasManualSp={!!precursorStatus}
              hasSkip={false}
              orderNumberList={[orderDetail.id]}
              onManualSpClick={() => {
                setIsOtpFrameDisabled(true);
              }}
              setDigitalSignatureVerified={setDigitalSignatureVerified}
              onSetIsOtpFrameDisabled={setIsOtpFrameDisabled}
              onVerified={handleVerified}
            />
          </S.Header>
        </ShowIf>
        <ShowIf condition={showPrekursor && shouldSkipOtpFrame}>
          <S.Header>
            <S.PrekursorHeader status={orderDetail.precursor_status?.status}>
              <PrekursorIcon />
              <S.PrekusorTitle>
                {t(`prekursor.title.${orderDetail.precursor_status?.status || 'required'}`)}
              </S.PrekusorTitle>
            </S.PrekursorHeader>
            <PrekursorDocument
              order={orderDetail}
              disableTitle
              t={t}
              style={{
                boxShadow: '0px 0px 1px rgba(99, 106, 122, 0.12), 0px 2px 10px rgba(58, 62, 71, 0.22)',
                borderRadius: '0px 0px 5px 5px',
                background: '#ffffff',
                padding: '0',
              }}
              onClose={onClosePrekursorDocument}
              digitalSignatureVerified={digitalSignatureVerified}
              onHandleShowOtpFrame={() => {
                setShouldShowOtpFrame(false);
              }}
            />
          </S.Header>
        </ShowIf>
        <ShowIf condition={!showPrekursor}>
          <S.Header>
            <RegularSPDocumentDownload
              t={t}
              style={{
                boxShadow: '0px 0px 1px rgba(99, 106, 122, 0.12), 0px 2px 10px rgba(58, 62, 71, 0.22)',
                borderRadius: '10px',
                background: '#ffffff',
                padding: '0',
              }}
              order={orderDetail}
              digitalSignatureVerified={digitalSignatureVerified}
            />
          </S.Header>
        </ShowIf>

        <ConditionalRender condition={isPrecursorRejected}>
          <S.PrecursorRejectedWrapper>
            <S.PrecursorRejectedTitle gutterBottom>{t('prekursor.rejected_reason')}</S.PrecursorRejectedTitle>
            <S.PrecursorRejectedReason>
              {precursorReasonsTranslated.length > 0
                ? precursorReasonsTranslated.join(', ')
                : orderDetail.precursor_status?.description}
            </S.PrecursorRejectedReason>
          </S.PrecursorRejectedWrapper>
          <S.DividerLine />
        </ConditionalRender>

        <OrdersModalSubTitle text={t('orderInformation')} />

        <ConditionalRender condition={orderedItems.length > 0}>
          <S.OrderedProductsList id="tour_orders_tab_products">
            <OrdersModalProductsPagination t={t} items={orderedItems} size={2} />
          </S.OrderedProductsList>
        </ConditionalRender>

        <ConditionalRender condition={returnedItems.length > 0}>
          <S.OrderedProductsList id="tour_orders_tab_products_returned" data-testid="orders-modal-returned-products">
            <Section title={t('return.title')} icon={OrderReturnedIcon} />
            <OrdersModalReturnPagination t={t} items={returnedItems} size={2} />
          </S.OrderedProductsList>
        </ConditionalRender>

        <ConditionalRender condition={deliveredItems.length > 0}>
          <S.OrderedProductsList id="tour_orders_tab_products">
            <Section title={t('delivered.title')} icon={OrderDeliveredIcon} />
            <OrdersModalProductsPagination t={t} items={deliveredItems} size={2} />
          </S.OrderedProductsList>
        </ConditionalRender>

        <ConditionalRender condition={orderDetail.status === OrderStatus.CANCELLED && orderDetail.is_returned}>
          <S.ReturnWrapper>
            <S.ReturnReasonTitle gutterBottom>{t('return.reason')}</S.ReturnReasonTitle>
            <S.ReturnReason>{orderDetail.return_reason}</S.ReturnReason>
          </S.ReturnWrapper>
        </ConditionalRender>

        <OrdersModalSubTitle text={t('paymentDetails')} />
        <OrdersModalSummaryPayments />
        <OrdersModalLoyaltyPoint />
        <ShowIf
          condition={shouldShowRating && [OrderStatus.FULFILLED, OrderStatus.COMPLETED].includes(orderDetail.status)}
        >
          <OrdersRatingEntrypoint />
        </ShowIf>

        <ConditionalRender condition={refunds.length > 0}>
          {refunds.map((refund) => (
            <>
              <OrdersModalRefundInfo refund={refund} taxRate={orderDetail.tax_rate} t={t} />
              <OrdersModalCreditMemo refund={refund} t={t} />
            </>
          ))}
        </ConditionalRender>
        <WidgetsContainerExtra display={!!widgets?.length}>
          <AfterListingWidgets widgets={widgets} />
        </WidgetsContainerExtra>
      </S.Wrapper>

      <OrderStatusModal open={orderStatus.isOpen} onClose={handleCloseStatus} />
      {loading && <LoadingPage inverse />}
    </Modal>
  );
};

OrdersModalBase.defaultProps = {
  returned: false,
};

const OrdersModalBaseWithWidget = withFeaturedMarketingWidget(OrdersModalBase);

export const OrdersModal: React.FC<any> = translate('orders')((props) => (
  <OrdersModalBaseWithWidget
    withMarketingFeatureWidget
    featureType={MarketingFeaturesType.TRACK_ORDER}
    showCondition
    {...props}
  />
));
