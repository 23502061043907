import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import { translate } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Button } from 'components/common/Button';
import * as colors from 'components/styles/colors';
import ShowIf from 'components/views/ShowIf';
import { swipeRxPt } from 'services';
import { TranslateProps } from 'utils/Localization';
import { getCustomerSuccessSupport } from 'utils/MarketConfig';

import { IDigitalSignature } from 'services/swipe-rx-pt/resources/digital-signature/interface/digitalSignature.interface';
import { OTP_ERROR_CODE_ENUM } from 'services/swipe-rx-pt/errors/error.enum';
import { OTP_MAX_INPUT, DS_STATUS_PICKED } from '../constants/digitalSignaturePage.constants';

import { Countdown } from './countdown.component';
import { DigitalSignatureVerified } from './otp-verified.component';
import { OTPErrorCard } from './otp-error-card.styles';
import { CustomOTPInput } from './custom-otp-input.component';

export interface OtpBaseProps extends TranslateProps {
  orderNumberList: any;
  isCardView?: boolean;
  hasManualSp?: boolean;
  hasSkip?: boolean;
  isOtpFrameDisabled?: boolean;
  setDigitalSignatureVerified?: (isVerified: boolean) => void;
  onManualSpClick?: () => void;
  onSkipClick?: () => void;
  onVerified: (digitalSignatures) => void;
  onSetIsOtpFrameDisabled?: (isOtpFrameDisabled: boolean) => void;
}

export const OtpBase: React.FC<React.PropsWithChildren<OtpBaseProps>> = (props) => {
  const location = useLocation();
  const isOrderConfirmed = location.pathname.includes('order-confirmed');
  const { orderNumberList, isCardView, t, hasManualSp, hasSkip = true, isOtpFrameDisabled = false } = props;
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasGenericError, setHasGenericError] = useState<boolean>(false);
  const [hasOtpInputError, setHasOtpInputError] = useState<boolean>(false);
  const [isInvalidated, setIsInvalidated] = useState<boolean>(false);
  const [isVerifyDisabled, setIsVerifyDisabled] = useState<boolean>(false);
  const [isAtMaxRetry, setIsAtMaxRetry] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const [digitalSignatures, setDigitalSignatures] = useState<IDigitalSignature[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<any>(false);
  const now = digitalSignatures ? digitalSignatures[0]?.created_at : moment();
  const eventTime = moment(now).add(48, 'hours').unix(); // TODO: SPT-21063
  const [isTimerEnded, setIsTimerEnded] = useState<any>(false);
  const customerSuccessSupport = getCustomerSuccessSupport();

  const mapErrorMessageByErrorCode = (message: string, errCode: string) => {
    const extractRemainingTries = (errMessage: string) => {
      const match = errMessage.match(/(\d+) tries left/);
      const remainingTries = match ? match[1] : 0;

      return remainingTries;
    };
    const remainingTries =
      errCode === OTP_ERROR_CODE_ENUM.OTP_AUTHENTICATED ||
      errCode === OTP_ERROR_CODE_ENUM.OTP_EXPIRED ||
      errCode === OTP_ERROR_CODE_ENUM.OTP_DIFFERENT_ORDERS ||
      OTP_ERROR_CODE_ENUM.OTP_INVALID
        ? extractRemainingTries(message)
        : 0;

    const OTP_ERROR_MAPPINGS = {
      [OTP_ERROR_CODE_ENUM.OTP_INVALID]: t('otpInvalidCode', { count: remainingTries }),
      [OTP_ERROR_CODE_ENUM.OTP_AUTHENTICATED]: t('otpAlreadyAuthenticated', { count: remainingTries }),
      [OTP_ERROR_CODE_ENUM.OTP_EXPIRED]: t('otpExpiredCode', { count: remainingTries }),
      [OTP_ERROR_CODE_ENUM.OTP_MAX_RETRIES]: t('otpMaxRetries'),
      [OTP_ERROR_CODE_ENUM.OTP_MAX_INVALID_ATTEMPTS]: t('otpMaxRetries'),
      [OTP_ERROR_CODE_ENUM.OTP_DIFFERENT_ORDERS]: t('otpDifferentOrder', { count: remainingTries }),
      [OTP_ERROR_CODE_ENUM.PO_ALREADY_ACCEPTED]: t('purchaseOrderAlreadyAccepted'),
      [OTP_ERROR_CODE_ENUM.SEND_MESSAGE_ERROR]: t('genericError'),
    };

    return OTP_ERROR_MAPPINGS[errCode] || t('genericError');
  };

  useEffect(() => {
    const isAuthenticated = digitalSignatures.find((digitalSignature) => !!digitalSignature.authenticated_at);
    if (isAuthenticated && props?.setDigitalSignatureVerified) props.setDigitalSignatureVerified(true);
  }, [isAuthenticated]);

  useEffect(() => {
    swipeRxPt.digitalSignature
      .retrieve(orderNumberList)
      .then((response) => {
        const {
          message,
          status,
          data: [digitalSignatureResponse],
        } = response;
        setDigitalSignatures(response?.data);
        if (response?.data instanceof Array && response?.data.length > 0) {
          /** Error Hierarchy: 3rd party > invalidated_reason > OTP validations */
          if (
            status !== 200 &&
            message &&
            response?.data?.[0]?.chat_error_response &&
            response?.code === OTP_ERROR_CODE_ENUM.SEND_MESSAGE_ERROR
          ) {
            setHasGenericError(true);
          } else if (
            (status !== 200 &&
              message &&
              response?.data?.[0]?.invalidated_reason &&
              response?.data?.[0]?.invalidated_at) ||
            (response?.data?.[0]?.invalidated_reason && response?.data?.[0]?.invalidated_at)
          ) {
            setErrorMessage(mapErrorMessageByErrorCode(message, OTP_ERROR_CODE_ENUM.PO_ALREADY_ACCEPTED));

            if (response.data[0].invalidated_reason === DS_STATUS_PICKED) {
              if (props?.onSetIsOtpFrameDisabled) props.onSetIsOtpFrameDisabled(true);
            }

            setIsInvalidated(true);
          } else if (status !== 200 && message) {
            setErrorMessage(
              mapErrorMessageByErrorCode(message, response?.code ?? OTP_ERROR_CODE_ENUM.SEND_MESSAGE_ERROR),
            );
            if (response?.code === OTP_ERROR_CODE_ENUM.OTP_MAX_RETRIES) {
              setIsAtMaxRetry(true);
            }
            if (response?.code === OTP_ERROR_CODE_ENUM.PO_ALREADY_ACCEPTED) {
              setIsInvalidated(true);
              if (props?.onSetIsOtpFrameDisabled) props.onSetIsOtpFrameDisabled(true);
            }

            if (
              [
                OTP_ERROR_CODE_ENUM.OTP_AUTHENTICATED,
                OTP_ERROR_CODE_ENUM.OTP_EXPIRED,
                OTP_ERROR_CODE_ENUM.OTP_MAX_INVALID_ATTEMPTS,
              ].includes(response?.code as OTP_ERROR_CODE_ENUM)
            ) {
              setIsVerifyDisabled(true);
            }
          } else setErrorMessage('');

          if (digitalSignatureResponse.authenticated_at) {
            setIsAuthenticated(true);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
        setShouldRefresh(false);
      });
  }, [orderNumberList.toString(), shouldRefresh]);

  const isAvailable = !!orderNumberList.filter((order) => !!order).length;
  if (!isAvailable) {
    return <></>;
  }

  const handleManualSpClicked = () => {
    if (props?.onManualSpClick) props.onManualSpClick();
    setIsInvalidated(true);
  };

  const handleSkipClicked = () => {
    if (props?.onSkipClick) props.onSkipClick();
  };

  const handleTimerEnd = () => {
    setIsTimerEnded(true);
  };

  const isResendCodeClickable = isTimerEnded && !isAtMaxRetry;
  const handleResetCode = () => {
    if (!isTimerEnded || !isResendCodeClickable) return;
    setIsLoading(true);
    setErrorMessage('');
    setHasOtpInputError(false);
    setHasGenericError(false);
    setIsInvalidated(false);
    swipeRxPt.digitalSignature
      .resend({
        purchase_order_ids: orderNumberList,
      })
      .then((response) => {
        setDigitalSignatures(response);
        setIsVerifyDisabled(false);
        setIsTimerEnded(false);
      })
      .catch((e) => {
        if (!Object.values(OTP_ERROR_CODE_ENUM).includes(e?.errorCode)) {
          setHasGenericError(true);
          return;
        }

        if (e?.errorCode === OTP_ERROR_CODE_ENUM.PO_ALREADY_ACCEPTED) {
          setIsInvalidated(true);
          if (props?.onSetIsOtpFrameDisabled) props.onSetIsOtpFrameDisabled(true);
        }

        if (
          [
            OTP_ERROR_CODE_ENUM.OTP_AUTHENTICATED,
            OTP_ERROR_CODE_ENUM.OTP_EXPIRED,
            OTP_ERROR_CODE_ENUM.OTP_MAX_INVALID_ATTEMPTS,
          ].includes(e?.errorCode as OTP_ERROR_CODE_ENUM)
        ) {
          setIsVerifyDisabled(true);
        }

        setErrorMessage(mapErrorMessageByErrorCode(e.message, e.errorCode));
        setHasOtpInputError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSend = () => {
    setIsLoading(true);
    setErrorMessage('');
    setHasOtpInputError(false);
    setHasGenericError(false);
    setIsInvalidated(false);
    swipeRxPt.digitalSignature
      .verify({
        code,
        purchase_order_ids: orderNumberList,
      })
      .then(({ data }) => {
        setDigitalSignatures(data);
        if (props?.onVerified) props.onVerified(data);
        setShouldRefresh(true);
      })
      .catch((e) => {
        if (!Object.values(OTP_ERROR_CODE_ENUM).includes(e?.errorCode)) {
          setHasGenericError(true);
          return;
        }

        if (e?.errorCode === OTP_ERROR_CODE_ENUM.PO_ALREADY_ACCEPTED) {
          setIsInvalidated(true);
          if (props?.onSetIsOtpFrameDisabled) props.onSetIsOtpFrameDisabled(true);
        }

        if (
          [
            OTP_ERROR_CODE_ENUM.OTP_AUTHENTICATED,
            OTP_ERROR_CODE_ENUM.OTP_EXPIRED,
            OTP_ERROR_CODE_ENUM.OTP_MAX_INVALID_ATTEMPTS,
          ].includes(e?.errorCode as OTP_ERROR_CODE_ENUM)
        ) {
          setIsVerifyDisabled(true);
        }

        setErrorMessage(mapErrorMessageByErrorCode(e.message, e.errorCode));
        setHasOtpInputError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpenChat = (): void => {
    window.open(customerSuccessSupport.link, customerSuccessSupport.target);
  };

  const buttonStyle = {
    margin: '0.3rem',
    borderRadius: 20,
    width: '90%',
    height: 32,
    fontWeight: 700,
    fontSize: 14,
  };

  const outlineButtonStyle = {
    margin: '0.3rem',
    borderRadius: 20,
    width: '90%',
    height: 32,
    fontWeight: 700,
    fontSize: 14,
    background: colors.WHITE,
    border: `1px solid ${colors.REBRAND_GREEN}`,
    color: `${colors.REBRAND_GREEN}`,
  };

  const title = isOrderConfirmed ? t('enterOtpOrderConfirmed') : t('enterOtpOrderDetails');

  const showOtpBase =
    (isOrderConfirmed && digitalSignatures[0]?.id) ||
    (!isOrderConfirmed && digitalSignatures[0]?.id) ||
    (isOrderConfirmed && !digitalSignatures[0]?.id);

  const digitalSignaturePoNums = digitalSignatures.map(
    (digitalSignature) => digitalSignature?.purchase_order?.po_number,
  );

  const errorContainer = (
    <>
      <Grid container alignItems="center" justifyContent="space-between" direction="column">
        <Grid item xs={12} style={{ width: '100%' }}>
          <OTPErrorCard title={t('genericError')} actionTitle={t('genericErrorActionTitle')} onClick={handleOpenChat} />
        </Grid>
      </Grid>
    </>
  );

  const otpContainer = (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        direction="column"
        style={isOtpFrameDisabled || isInvalidated ? { opacity: 0.4 } : {}}
      >
        <Grid item xs={12} style={{ textAlign: isOrderConfirmed ? 'left' : 'center', width: '100%' }}>
          <Typography
            style={{
              fontFamily: 'Nunito Sans',
              fontSize: 13,
              fontWeight: 700,
              padding: '0rem 0px 0px 0.8rem',
            }}
          >
            {title}
          </Typography>
        </Grid>
        <ShowIf condition={isOrderConfirmed}>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Typography
              color="textPrimary"
              style={{
                fontFamily: 'Nunito Sans',
                fontSize: 13,
                padding: '0rem 0px 0px 0.8rem',
              }}
            >
              {t('enterOTPWeSent')}
            </Typography>
          </Grid>
        </ShowIf>
        <ShowIf condition={errorMessage !== ''}>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Typography
              color="textPrimary"
              style={{
                fontFamily: 'Nunito Sans',
                fontSize: 10,
                padding: '0rem 0px 0px 0.8rem',
                color: 'red',
                textAlign: 'center',
              }}
            >
              {errorMessage}
            </Typography>
          </Grid>
        </ShowIf>

        <Grid item xs={12} style={{ padding: '1rem 0px 0px 0px' }}>
          <OtpInput
            skipDefaultStyles
            value={code}
            inputType="number"
            onChange={setCode}
            numInputs={OTP_MAX_INPUT}
            renderInput={(props) => (
              <CustomOTPInput
                disabled={isInvalidated || isOtpFrameDisabled}
                {...props}
                hasErrored={Boolean(!!errorMessage && code && hasOtpInputError)}
                errorStyle={{
                  borderBottom: `2px solid ${colors.DANGER_DARKER}`,
                }}
              />
            )}
            containerStyle={{
              display: 'flex',
              gap: '8px',
            }}
          />
        </Grid>
        <Grid item>
          <div
            style={{
              fontFamily: 'Nunito Sans',
              fontSize: 9,
              fontWeight: 700,
              padding: '0.3rem',
              textAlign: 'center',
            }}
          >
            {t('enterCode')}
          </div>
        </Grid>
        <ShowIf condition={!isInvalidated}>
          <Grid item>
            <div
              style={{
                fontFamily: 'Nunito Sans',
                fontSize: 13,
                fontWeight: 700,
                textAlign: 'center',
                padding: '1rem',
                cursor: isResendCodeClickable ? 'pointer' : 'default',
                color: isResendCodeClickable ? colors.PRIMARY_COLOR : colors.GRAY31,
              }}
              role="button"
              onClick={handleResetCode}
              onKeyDown={handleResetCode}
              tabIndex={0}
            >
              {isTimerEnded ? t('resendCode') : t('resendCodeInTimer', {})}
              {!isTimerEnded && (
                <Countdown eventTime={eventTime} interval={1000} key={eventTime} onTimerEnded={handleTimerEnd} />
              )}
            </div>
          </Grid>
        </ShowIf>
        <Grid item>
          <div
            style={{
              fontFamily: 'Nunito Sans',
              fontSize: 13,
              fontWeight: 400,
              textAlign: 'center',
              padding: '0rem 0px 0px 0.8rem',
            }}
          >
            {t('agreeTermsOnSend')}
          </div>
        </Grid>
        <Grid item xs={12} style={{ width: '90%', textAlign: 'center' }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            data-testid="verify-btn"
            onClick={handleSend}
            disabled={isInvalidated || isOtpFrameDisabled || isVerifyDisabled || code.toString().length < OTP_MAX_INPUT}
            style={buttonStyle}
          >
            {isLoading ? <CircularProgress size={16} style={{ color: colors.WHITE }} /> : t('send')}
          </Button>
          <ShowIf condition={hasSkip}>
            <Button
              fullWidth
              color="inherit"
              variant="contained"
              data-testid="skip-btn"
              onClick={handleSkipClicked}
              style={outlineButtonStyle}
            >
              {t('skip')}
            </Button>
          </ShowIf>
          <ShowIf condition={Boolean(hasManualSp)}>
            <Button
              disabled={isOtpFrameDisabled || isInvalidated}
              fullWidth
              color="default"
              variant="contained"
              data-testid="manualSp-btn"
              onClick={handleManualSpClicked}
              style={outlineButtonStyle}
            >
              {t('manualSp')}
            </Button>
          </ShowIf>
        </Grid>
      </Grid>
    </>
  );

  return isCardView ? (
    <>
      <ShowIf condition={!!showOtpBase && !isAuthenticated}>
        <ShowIf condition={hasGenericError}>{errorContainer}</ShowIf>
        <Card
          square
          elevation={0}
          style={{
            boxShadow: '0px 0px 1px rgba(99, 106, 122, 0.12), 0px 2px 10px rgba(58, 62, 71, 0.22)',
            borderRadius: '10px 10px 10px 10px',
            background: `${colors.WHITE}`,
            padding: '0',
          }}
        >
          <CardContent>{otpContainer}</CardContent>
        </Card>
      </ShowIf>
      <ShowIf condition={isAuthenticated}>
        <DigitalSignatureVerified orderNumberList={digitalSignaturePoNums} />
      </ShowIf>
    </>
  ) : (
    <>
      <ShowIf condition={!!showOtpBase && !isAuthenticated}>
        <ShowIf condition={hasGenericError}>{errorContainer}</ShowIf>
        {otpContainer}
      </ShowIf>
      <ShowIf condition={isAuthenticated}>
        <DigitalSignatureVerified orderNumberList={digitalSignaturePoNums} />
      </ShowIf>
    </>
  );
};

export const OtpFrame = translate('digitalSignature')(OtpBase);
